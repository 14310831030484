// src/components/MapComponent.js
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { MapContainer, TileLayer, Marker, Popup, /*Polygon*/ GeoJSON, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import "../../css/MapComponent.css";
import L from 'leaflet';
import Switch from 'react-switch';


// Icônes pour les marqueurs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const yellowIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const orangeIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const grayIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const greenIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const redIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const blueIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});


// Icone personnalisée pour la position de l'utilisateur
const blueIcon2 = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x-blue.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});



const MapComponent = (props) => {
  const [elementaire, setElementaire] = useState([]);
  const [checked_elementaire, setChecked_Elementaire] = useState(true);
  const [cem, setCem] = useState([]);
  const [checked_cem, setChecked_cem] = useState(true);
  const [lycee, setLycee] = useState([]);
  const [checked_lycee, setChecked_lycee] = useState(true);
  const [daara, setDaara] = useState([]);
  const [checked_daara, setChecked_daara] = useState(true);
  const [franco, setFranco] = useState([]);
  const [checked_franco, setChecked_franco] = useState(true);
  const [poste, setPoste] = useState([]);
  const [checked_poste, setChecked_poste] = useState(true);
  const [limite, setLimite] = useState([]);
  const [region, setRegion] = useState(null);
  const [departement, setDepartement] = useState(null)
  const [commune, setCommune] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(7); // Initialiser le niveau de zoom
  const [isBlinking, setIsBlinking] = useState(true);


    
  /****************EDUCATION OU SANTE*****************/
   // Composant pour surveiller les changements de zoom
   useEffect(() => {

    if (!props.isEducation) {
      setChecked_Elementaire(false);
    setChecked_cem(false);
    setChecked_daara(false);
    setChecked_franco(false);
    setChecked_lycee(false);
    setChecked_poste(true); 
    } else {
      setChecked_Elementaire(true);
      setChecked_cem(true);
      setChecked_daara(true);
      setChecked_franco(true);
      setChecked_lycee(true);
      setChecked_poste(false);
    }

   
   },[props.isEducation])

  /*******************LE ZOOM***********************/
   // Composant pour surveiller les changements de zoom
   const ZoomListener = () => {
    const map = useMapEvents({
      zoomend: () => {
        setZoomLevel(map.getZoom()); // Mettre à jour le niveau de zoom à chaque changement
        console.log(zoomLevel);
        
      },
    });
    return null; // Pas besoin de rendre d'élément
  };
  
  //region
  const fetchLimite = async () => {
    const response = await fetch('/limites.json'); // Récupération du fichier depuis le dossier public
    const data = await response.json(); // Conversion de la réponse en JSON
    console.log('ici');
    console.log(props.isEducation);
    setLimite(data); // Mise à jour de l'état avec les données JSON
  };

  //region
const fetchRegion = async () => {
  const response = await fetch('/regions.json'); // Récupération du fichier depuis le dossier public
  const data = await response.json(); // Conversion de la réponse en JSON
  console.log('ici');
  console.log(data);
  setRegion(data); // Mise à jour de l'état avec les données JSON
};

  //departement
  const fetchDepartement = async () => {
    const response = await fetch('/departements.json'); // Récupération du fichier depuis le dossier public
    const data = await response.json(); // Conversion de la réponse en JSON
    console.log('ici');
    console.log(data);
    setDepartement(data); // Mise à jour de l'état avec les données JSON
  };

  const fetchCommune = async () => {
    const response = await fetch('/communes.json'); // Récupération du fichier depuis le dossier public
    const data = await response.json(); // Conversion de la réponse en JSON
    console.log('ici');
    console.log(data);
    setCommune(data); // Mise à jour de l'état avec les données JSON
  };

  //elementaire
  useEffect(() => {
    const fetchElementaire = async () => {
      const response = await fetch('/education.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const elementaire = new Uint8Array(event.target.result);
        const workbook = XLSX.read(elementaire, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonElementaire = XLSX.utils.sheet_to_json(worksheet);
        setElementaire(jsonElementaire);
      };
      reader.readAsArrayBuffer(blob);
    };
    //college 
    const fetchCem = async () => {
      const response = await fetch('/cem.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const cem = new Uint8Array(event.target.result);
        const workbook = XLSX.read(cem, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setCem(jsonData);
      };
      reader.readAsArrayBuffer(blob);
    };

    //lycee
    const fetchLycee = async () => {
      const response = await fetch('/lycee.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const lycee = new Uint8Array(event.target.result);
        const workbook = XLSX.read(lycee, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setLycee(jsonData);
      };
      reader.readAsArrayBuffer(blob);
    };

     //daara
     const fetchDaara = async () => {
      const response = await fetch('/daara.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const daara = new Uint8Array(event.target.result);
        const workbook = XLSX.read(daara, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setDaara(jsonData);
      };
      reader.readAsArrayBuffer(blob);
    };

     //franco
     const fetchFranco = async () => {
      const response = await fetch('/franco.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const franco = new Uint8Array(event.target.result);
        const workbook = XLSX.read(franco, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setFranco(jsonData);
      };
      reader.readAsArrayBuffer(blob);
    };

    
     //poste
     const fetchPoste = async () => {
      const response = await fetch('/poste.xlsx');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (event) => {
        const poste = new Uint8Array(event.target.result);
        const workbook = XLSX.read(poste, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setPoste(jsonData);
      };
      reader.readAsArrayBuffer(blob);
    };

   /* //limite communale
    const fetchPolygon = async () => {
      
      const coords = [
      
[14.326837, -15.687791 ],
[14.365654, -15.584787],
[14.380464, -15.566695],
[14.399113, -15.550513],
[14.401788, -15.547782],
[14.402603, -15.546475],
[14.403188, -15.544922],
[14.403441, -15.535855],
[14.403802, -15.530845],
[14.403935, -15.524163],
[14.404527, -15.517849],
[14.406027, -15.512424],
[14.407388, -15.504287],
[14.413960, -15.495329],
[14.448355, -15.440080],
[14.447772, -15.432907],
[14.446035, -15.426112],
[14.455243, -15.414190],
[14.463149, -15.406296],
[14.424299, -15.381473],
[14.421101, -15.380996],
[14.417991, -15.381078],
[14.409320, -15.382968],
[14.406369, -15.384167],
[14.404012, -15.385633],
[14.400483, -15.384328],
[14.39887, -15.38246],
[14.3782, -15.3720],
[14.3428, -15.3737],
[14.3242, -15.4103],
[14.3220, -15.4319],
[14.2878, -15.4491],
[14.2896, -15.4816],
[14.2881, -15.4863],
[14.2859, -15.4882],
[14.2830, -15.4885],
[14.2792, -15.4855],
[14.2605, -15.4595],
[14.25730, -15.45760],
[14.2540, -15.4572],
[14.2492, -15.4545],
[14.2475, -15.4515],
[14.2465, -15.4488],
[14.2461, -15.4464],
[14.24626,-15.44362],
[14.24837, -15.43934],
[14.24953, -15.43427],
[14.24883, -15.43162],
[14.24767, -15.43024],
[14.24555, -15.42901],
[14.24246, -15.42901],
[14.23827, -15.43024],
[14.2363, -15.4326],
[14.2283, -15.4398],
[14.1935, -15.4552],
[14.1888, -15.5169],
[14.19981, -15.52542],
[14.1905, -15.5456],
[14.1883, -15.5511],
[14.18373, -15.55799],
[14.18093, -15.56086],
[14.1763, -15.5642],
[14.1674, -15.5676],
[14.1538, -15.5746],
[14.1516, -15.5796],
[14.1519, -15.5780],
[14.1425, -15.6095],
[14.1658, -15.6325],
[14.2741, -15.6824],
[14.286, -15.6942],





      ];
      setPolygon(coords);
    };*/

  const Blink_ = () => {
    const blinkInterval = setInterval(() => {
      setIsBlinking((prev) => !prev); // Inverse l'état clignotant toutes les 500ms
    }, 500); // 500ms pour un clignotement rapide

    return () => clearInterval(blinkInterval); // Nettoyage de l'intervalle
  }

    fetchElementaire();
    fetchCem();
    fetchLycee();
    fetchDaara();
    fetchFranco();
    fetchPoste();
    /*fetchPolygon();*/
    fetchRegion();
    fetchDepartement();
    fetchCommune();
    fetchLimite();
    Blink_();
  }, []);


  

/***********************************************************************************************/
/****************************************CLIGNOTEMENT ZONE CIBLE******************************************************/
/***********************************************************************************************/



  const polygonStyle = {
    color: 'black', // Couleur des bordures
    weight: 1, // Épaisseur des bordures
    fillOpacity: isBlinking ? 0.5 : 0.1, // Opacité changeante pour clignoter
    opacity: 1, // Opacité des bordures
    fillColor: isBlinking ? 'red' : 'transparent', // Change la couleur entre "red" et "transparent"
  };
  const popupStyle = {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    width: '100%',
  };





  
  return (
 
   
    <MapContainer center={[14.3, -15.5]} zoom={zoomLevel} style={{ height: "100%", width: "100%" }} zoomControl={false} >
 {/* Listener pour détecter les changements de zoom */}
 <ZoomListener />
      <div className='c1' >
      <h4>Légende</h4>
   {props.isEducation ? (
       <div className='container_'>
       <div className='elements'>
   <div className='title'>Élémentaire</div>
   <div className='color-elementaire'></div>
   <div className='check'>
     <Switch
       checked={checked_elementaire === true}
       onChange={() => { checked_elementaire ? setChecked_Elementaire(false) : setChecked_Elementaire(true) }}
       offColor="#888"
       onColor="#198754"
     />
   </div>
 </div>
 
 <div className='elements'>
   <div className='title'>Secondaire</div>
   <div className='color-secondaire'></div>
   <div className='check'>
     <Switch
       checked={checked_cem === true}
       onChange={() => { checked_cem ? setChecked_cem(false) : setChecked_cem(true) }}
       offColor="#888"
       onColor="#198754"
     />
   </div>
 </div>
 
 <div className='elements'>
   <div className='title'>Lycée</div>
   <div className='color-lycee'></div>
   <div className='check'>
     <Switch
       checked={checked_lycee === true}
       onChange={() => { checked_lycee ? setChecked_lycee(false) : setChecked_lycee(true) }}
       offColor="#888"
       onColor="#198754"
     />
   </div>
 </div>
 
 <div className='elements'>
   <div className='title'>Franco-arabe</div>
   <div className='color-franco'></div>
   <div className='check'>
     <Switch
       checked={checked_franco === true}
       onChange={() => { checked_franco ? setChecked_franco(false) : setChecked_franco(true) }}
       offColor="#888"
       onColor="#198754"
     />
   </div>
 </div>
 
 <div className='elements'>
   <div className='title'>Daara</div>
   <div className='color-daara'></div>
   <div className='check'>
     <Switch
       checked={checked_daara === true}
       onChange={() => { checked_daara ? setChecked_daara(false) : setChecked_daara(true) }}
       offColor="#888"
       onColor="#198754"
     />
   </div>
 </div>
 
 
 
       </div>
   
   ):(
          <div className='container_' >
      <div className='elements'>
  <div className='title'>Poste de santé</div>
  <div className='color-poste'></div>
  <div className='check'>
    <Switch
      checked={checked_poste === true}
      onChange={() => { checked_poste ? setChecked_poste(false) : setChecked_poste(true) }}
      offColor="#888"
      onColor="#198754"
    />
  </div>
</div>
      </div>
     
)}
      </div>




      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      

      
      { checked_elementaire && zoomLevel >=10 && elementaire.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={grayIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.ecole}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
        <tr  >
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
            fontSize:"15px"
          }}>Effectif:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school.effectif}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Ratio élève-maître:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["ratio-eleve-maitre"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Taux de réussite:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["taux-reussite"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Eau potable:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.eau_potable === 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Électricité:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.electricite === 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</Popup>

        </Marker>
      ))}


{/*Poour les College ici !*/}
{checked_cem && zoomLevel >=10 &&  cem.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={greenIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.ecole}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
        <tr  >
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
            fontSize:"15px"
          }}>Effectif:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school.effectif}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Ratio élève-maître:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["ratio-eleve-maitre"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Taux de réussite:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["taux-reussite"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Eau potable:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.eau_potable === 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Électricité:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.electricite === 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</Popup>

        </Marker>
      ))}


{/*Poour les College ici !*/}
{checked_lycee && zoomLevel >=10 &&  lycee.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={redIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.ecole}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
        <tr  >
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
            fontSize:"15px"
          }}>Effectif:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school.effectif}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Ratio élève-maître:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["ratio-eleve-maitre"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Taux de réussite:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["taux-reussite"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Eau potable:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.eau_potable !== 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Électricité:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school.electricite === 'oui' ? 'Disponible' : 'Pas disponible'}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</Popup>

        </Marker>
      ))}



      {/*Poour les daara ici !*/}
{checked_daara && zoomLevel >=10 &&  daara.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={orangeIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.ecole}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
        <tr  >
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
            fontSize:"15px"
          }}>Effectif:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school.effectif}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Nombre de filles:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Nombre de filles"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Nombre de garcons:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Nombre de garcons"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Internat:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school["internat"]}
          </td>
        </tr>
       
      </tbody>
    </table>
  </div>
</Popup>

        </Marker>
      ))}


     {/*Poour les daara ici !*/}
     {checked_franco && zoomLevel >=10 &&  franco.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={yellowIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.ecole}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
        <tr  >
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
            fontSize:"15px"
          }}>Effectif:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school.effectif}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Nombre de filles:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Nombre de filles"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Nombre de garcons:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Nombre de garcons"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Internat:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school["internat"]}
          </td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Niveau:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school["Niveau"]}
          </td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Taux de reussite :</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>
            {school["taux-de-reussite"]}
          </td>
        </tr>
       
      </tbody>
    </table>
  </div>


  
</Popup>

        </Marker>
      ))}


{checked_poste && zoomLevel >=10 &&  poste.map((school, index) => (
        <Marker key={index} position={[school.latitude, school.longitude]} icon={blueIcon} >
         <Popup>
  <div style={popupStyle}>
    <strong>{school.nom}</strong>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px'
    }}>
      <tbody>
      <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Population de la zone de polarization:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Population de la zone de polarization"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>infirmiers:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["infirmiers"]}</td>
        </tr>
      
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Effectif personnel d’appui:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Effectif personnel d’appui"]}</td>
        </tr>
        <tr>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f9f9f9',
               fontSize:"15px"
          }}>Disponibilité de système d’évacuation d’urgence:</td>
          <td style={{
            border: '1px solid #ccc',
            padding: '8px',
               fontSize:"15px"
          }}>{school["Disponibilité de système d’évacuation d’urgence"]}</td>
        </tr>
      
      </tbody>

      
    </table>
  </div>


  
</Popup>

        </Marker>
      ))}

      {/* {polygon.length > 0 && (
        <Polygon  positions={polygon} color="indigo" fillOpacity={0.2} />
      )} */}
      {/* Afficher les polygones si les données sont chargées */}
      
      {region && zoomLevel <= 7 && <GeoJSON color="black" style={{
    color: 'black', // Couleur des bordures (hex, nom, ou rgb)
    weight: 1, // Épaisseur des bordures
    fillOpacity: 0, // Opacité du remplissage (0 pour pas de fond)
    opacity: 1, // Opacité des bordures
  }} data={region} /> }
  
  
  { departement && zoomLevel > 7 && departement &&  zoomLevel <= 9 &&  <GeoJSON color="red" style={{
    color: 'black', // Couleur des bordures (hex, nom, ou rgb)
    weight: 1, // Épaisseur des bordures
    fillOpacity: 0, // Opacité du remplissage (0 pour pas de fond)
    opacity: 1, // Opacité des bordures
  }} data={departement} />}

{ commune && zoomLevel > 9 && commune &&  zoomLevel <= 11 &&  <GeoJSON color="red" style={{
    color: 'black', // Couleur des bordures (hex, nom, ou rgb)
    weight: 1, // Épaisseur des bordures
    fillOpacity: 0, // Opacité du remplissage (0 pour pas de fond)
    opacity: 1, // Opacité des bordures
  }} data={commune} />}

{ limite && zoomLevel > 9 && limite &&  zoomLevel <= 13 &&   <GeoJSON color="red" style={{
    color: 'black', // Couleur des bordures (hex, nom, ou rgb)
    weight: 1, // Épaisseur des bordures
    fillOpacity: 0.2, // Opacité du remplissage (0 pour pas de fond)
    opacity: 1, // Opacité des bordures
  }} data={limite} />}

{ region && commune && zoomLevel < 10 &&   <GeoJSON color="red"  style={polygonStyle} data={limite} 

onEachFeature={(feature, layer) => {
  layer.on({
    mouseover: (e) => {
      layer.bindPopup(`<strong>La commune de Boulel</strong>`).openPopup();
    },
    mouseout: (e) => {
      layer.closePopup();
    }
  });
}}
/>}



    </MapContainer>

  );
};

export default MapComponent;
